import React, { useState, useRef } from 'react';

const AudioPlayer = ({ audioUrl }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div>
            <button onClick={handlePlayPause}>
                {isPlaying ? '⏸️' : '▶️'}
            </button>
            <audio ref={audioRef} src={audioUrl} />
        </div>
    );
};

export default AudioPlayer;
