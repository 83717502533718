
function Tail() {
    return (
      <footer>
        <div className='tail-container'>
          <div className='container-center top-border'>
            <p>Abmedia © 2022 - 2024 v{process.env.REACT_APP_VERSION}</p>
            <p>Contact us: <a href="mailto:it@abmedia.ph">it@abmedia.ph</a></p>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Tail;
  